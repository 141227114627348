import React, { useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { Tabs, Divider, Input, Select, Button, Form, Row, Col } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';

const { Option } = Select;
const { TextArea } = Input;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

const formInit = {
  _id: null,
  title: {},
  googleDescription: {},
  shortDescription: {},
  keywords: {},
  content: {},
  tag: [],
  status: 'ACTIVE',
  image: undefined,
};

const PostForm = ({ isNew, data, onSubmit, language, tags, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let initialValues = { ...formInit, ...data };

  useEffect(() => {
    if (data && data.image) setImage(data.image.url);
  }, [data]);

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'image' });
  };

  const deleteImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ image: null });
  };

  const onInsertIMage = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code} forceRender>
                      <Form.Item
                        label='Naziv posta'
                        rules={[
                          {
                            required: lang.code === language.default.code ? true : false,
                            message: 'Molim vas da unesete naziv posta!',
                          },
                        ]}
                        name={['title', lang.code]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item label='Google opis' name={['googleDescription', lang.code]}>
                        <Input />
                      </Form.Item>

                      <Form.Item label='Google ključne reči' name={['keywords', lang.code]}>
                        <Input />
                      </Form.Item>

                      <Form.Item 
                          label='Kratak opis' 
                          name={['shortDescription', lang.code]}>
                        <TextArea rows={2} placeholder="maksimalan broj karaktera je 330" maxLength={330} />
                      </Form.Item>

                      <Form.Item label='Sadržaj posta' name={['content', lang.code]}>
                        <JoditEditor
                          name={`content[${lang.code}]`}
                          style={{ margin: '2px 0px', minHeight: '350px' }}
                          config={configJodit}
                        />
                      </Form.Item>
                      <Form.Item label='Tagovi' name={['tag']}>
                        <Select mode='multiple'>
                          {tags.map((item, index) => (
                            <Select.Option key={index} value={item._id}>
                              {item.title[lang.code]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label='Status'
                        name={['status']}
                        rules={[{ required: true, message: 'Molim vas da odaberete status!' }]}
                      >
                        <Select>
                          <Option value='ACTIVE'>ACTIVE</Option>
                          <Option value='INACTIVE'>INACTIVE</Option>
                        </Select>
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                <Divider type='horizontal' />
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Post slika</h4>
                </div>

                <Form.Item name='image' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox
                      editHandler={imageHandler}
                      deleteHandler={deleteImageHandler}
                      image={image}
                      index={0}
                      name='image'
                    />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'ažuriraj'} post
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/posts/'
            imageType='post'
            imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsertIMage(values, modal.formKey)}
          />
        )}
      </div>
    </div>
  );
};

export default PostForm;
