import React, { useState, useEffect, useContext, useRef } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
// import { UserForm } from '../../components/forms';
import { ClientForm } from '../../components/forms';

const EditClientModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [client, fetchClient] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const clientId = props.clientId;
  const { showModal, setShowModal } = props;
  // const [clientData, setClientData] = useState(undefined);

  useEffect(() => {
    if (clientId) {
      fetchClient(`${SERVER_URL}/clients/${clientId}`, []);
    }
  }, [clientId, fetchClient]);

  const updateClient = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/clients/${clientId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Komitent je ažuriran.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/clients');
    } catch (err) {
      notification.error({
        message: 'Problem sa ažuriranjem. Molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const createClient = async (data) => {
    // setClientData(data);
    try {
      let isError = false;
      // if (data.firstName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid first name!',
      //     placement: 'bottomRight',
      //   });
      // }
      if (data.name.trim() === '') {
        isError = true;
        notification.error({
          message: 'Nevažeći naziv!',
          placement: 'bottomRight',
        });
      }
      // if (data.lastName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid last name!',
      //     placement: 'bottomRight',
      //   });
      // }
      if (data.password.includes(' ')) {
        isError = true;
        notification.error({
          message: 'Nevažeća šifra!',
          placement: 'bottomRight',
        });
      }
      if (data.city.trim() === '') {
        isError = true;
        notification.error({
          message: 'Nevažeći grad!',
          placement: 'bottomRight',
        });
      }
      // if (data.country.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid country!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.companyName && data.companyName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid company name!',
      //     placement: 'bottomRight',
      //   });
      // }
      if (data.taxId && data.taxId.trim() === '') {
        isError = true;
        notification.error({
          message: 'Nevažeći PIB!',
          placement: 'bottomRight',
        });
      }
      if (isError === false) {
        try {
          const response = await Axios.post(`${SERVER_URL}/clients`, { ...data });
          console.log('response front: ', response);
          notification.success({
            message: 'Komitent je kreiran.',
            placement: 'bottomRight',
          });
          setResult(true);
        } catch (err) {
          console.log('error: ', err);
        }
        // const response = await Axios.post(`${SERVER_URL}/clients`, { ...data });
        // console.log('response front: ', response);
        // notification.success({
        //   message: 'Komitent je kreiran.',
        //   placement: 'bottomRight',
        // });
        // setResult(true);
      }
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa kreiranjem komitenta. Molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} />
      <div className={showModal ? 'modal show' : 'modal'}>
        {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

        <div style={{ textAlign: 'center', height: '100%' }}>
          {clientId && client.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {clientId && !client.isLoading && !client.isError && client.data && client.data.email && (
            <ClientForm
              data={client.data}
              updateHandler={updateClient}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
            />
          )}
          {clientId && !client.isLoading && client.isError && (
            <h2 style={{ marginTop: '5rem' }}>Nešto nije u redu:(</h2>
          )}
          {!clientId && client && !client.data && (
            <ClientForm
              data={null}
              createHandler={createClient}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditClientModal;
