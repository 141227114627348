import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Layout, Tabs, Typography, Card, Modal } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import moment from 'moment';
import EditClientModal from '../../components/modals/EditClientModal';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const ViewClient = (props, editPath) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios('', null, currentuser.data.token, 'get');
  // const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  // const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchClient(`${SERVER_URL}/clients/${id}`, []);
      // fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchClient]);
  // }, [id, fetchClient, fetchLog]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  // useEffect(() => {
  //   if (id) {
  //     let sevenDayBefor = moment().subtract(7, 'day').format('YYYY-MM-DD');
  //     let sevenDayBeforGet = moment(sevenDayBefor).format('YYYY-MM-DD');
  //     let from = sevenDayBeforGet;
  //     let to = new Date();
  //     let fromTo = from + '||' + to;
  //     fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
  //   }
  // }, [id, fetchCSV]);

  // const updateUser = async (data) => {
  //   if (data) {
  //     let fromTo = data.from + '||' + data.to;
  //     fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
  //   }
  // };

  // const handleSignOutSubmit = async (event) => {
  //   event.preventDefault();
  //   sessionStorage.removeItem('user');
  //   window.location.href = '/login';
  // };

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/clients'>
          <Button type='primary'>Svi komitenti</Button>
        </Link>
      </div>

      <div className='content content-full-height'>
        {id && client.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !client.isLoading && client.isError && <h2 style={{ marginTop: '5rem' }}>Nešto nije u redu :(</h2>}
        {id && client && !client.isLoading && !client.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              {/* <div className='logo' style={{ height: 'auto' }}>
                <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} /> */}

              {/*<Link to={'/admin/edit-user/' + id}>*/}
              <Button
                type='primary'
                block
                icon={<EditOutlined />}
                style={{ marginTop: '1rem' }}
                onClick={() => setShowModal(!showModal)}
              >
                IZMENI KOMITENTA
              </Button>
              {/*</Link>*/}
              {/* </div> */}
              <Content style={{ padding: '15px', minHeight: 280, color: '#000' }}>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Email:</span> {client.data ? client.data.email : 'Email'}
                </p>
                {/* <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  <span className='bold-label'>Rola:</span> {client.data ? client.data.role[0] : 'Role'}
                </p> */}
                {/* <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Status:</span> {client.data ? client.data.status : 'Status'}
                </p> */}
                {/* <Button className='brown-border-button' type='secondary' onClick={handleSignOutSubmit}>
                  Odjavi se
                </Button> */}
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFIL' key='1'>
                  <div className='card-wrapper'>
                    <Card title='Podaci komitenta' bordered={false}>
                      {/* <p>
                        <Text strong>Ime: </Text>
                        {client.data ? client.data.firstName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Prezime: </Text>
                        {client.data ? client.data.lastName : 'No data'}
                      </p> */}
                      <p>
                        <Text strong>Naziv: </Text>
                        {client.data ? client.data.name : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Adresa: </Text>
                        {client.data ? client.data.address : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Grad: </Text>
                        {client.data ? client.data.city : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {client.data ? client.data.email : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>PIB: </Text>
                        {client.data ? client.data.taxId : 'Nema podataka'}
                      </p>
                      <p>
                        <Text strong>Telefon: </Text>
                        {client.data ? client.data.phone : 'Nema podataka'}
                      </p>
                      {/* <p style={{ textTransform: 'capitalize' }}>
                        <Text strong>Rola: </Text>
                        {client.data ? client.data.role[0] : 'No data'}
                      </p> */}
                      {/* <p>
                        <Text strong>Status: </Text>
                        {client.data ? client.data.status : 'No data'}
                      </p> */}
                    </Card>
                  </div>
                </TabPane>

                {/* <TabPane tab='LOGS' key='2'> */}
                {/* <TabPane tab='PRIJAVE' key='2'>
                  {log.data.length > 0 ? (
                    <UserLog data={log.data} userId={id} updateHandler={updateUser} csv={csv.data} />
                  ) : (
                    'Nema podataka'
                  )}
                </TabPane> */}
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      <EditClientModal clientId={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default ViewClient;
