import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Card, Select, Result, Radio, notification, Modal } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  // email: undefined,
  // firstName: undefined,
  // lastName: undefined,
  // password: undefined,
  // phone: '',
  // legalType: 'PRIVATE',
  // address: undefined,
  // city: undefined,
  // zip: undefined,
  // country: undefined,
  // avatar: undefined,
  // role: [],
  name: undefined,
  address: undefined,
  city: undefined,
  email: undefined,
  taxId: undefined,
  phone: '',
  password: undefined,
};

const ClientForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  // const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let client = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName, setPromptName] = useState();
  const [deleteClient, setDeleteClient] = useState();
  const [deletePop, setDeletePop] = useState();

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const handleLegalType = (e) => {
  //   setLegalType(e.target.value);
  //   form.setFieldsValue({ legalType: e.target.value });
  // };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Komitent je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        history.push('/admin/clients');
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onClickHandler = () => {
    setShowModal(!showModal);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'Novi komitent' : 'Izmeni komitenta'}`} bordered={false}>
            {profile && (
              <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
                Obriši komitenta <DeleteOutlined />
              </div>
            )}
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={client}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                {/* <Form.Item className='upload-wrapper right' name='avatar'>
                  <UploadBox
                    editHandler={avatarHandler}
                    deleteHandler={deleteAvatarHandler}
                    image={image}
                    index={0}
                    name='avatar'
                  />
                </Form.Item>
                <Form.Item name='legalType'>
                  <Radio.Group onChange={handleLegalType}>
                    <Radio value='PRIVATE'>Private</Radio>
                    <Radio value='COMPANY'>Company</Radio>
                  </Radio.Group>
                </Form.Item> */}

                <Form.Item
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite naziv!',
                    },
                  ]}
                >
                  <Input placeholder='Naziv' />
                </Form.Item>

                <Form.Item name='address'>
                  <Input
                    placeholder='Adresa'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas unesite adresu!',
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  name='city'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite naziv Grada!',
                    },
                  ]}
                >
                  <Input placeholder='Grad' />
                </Form.Item>

                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite email adresu!',
                    },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  name='taxId'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite PIB!',
                    },
                  ]}
                >
                  <Input placeholder='PIB' />
                </Form.Item>

                {/* <Form.Item
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter first name!',
                    },
                  ]}
                >
                  <Input placeholder='First name' />
                </Form.Item>

                <Form.Item
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter last name!',
                    },
                  ]}
                >
                  <Input placeholder='Last name' />
                </Form.Item> */}

                <Form.Item name='phone'>
                  <Input
                    placeholder='Broj telefona'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas unesite broj telefona!',
                      },
                    ]}
                  />
                </Form.Item>

                {/* <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder='Password' />
                </Form.Item> */}

                {/* <Form.Item name='zip'>
                  <Input placeholder='Zip' />
                </Form.Item> */}

                {/* <Form.Item
                  name='country'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter country!',
                    },
                  ]}
                >
                  <Input placeholder='Country' />
                </Form.Item> */}

                {/* {legalType === 'COMPANY' && (
                  <>
                    <Form.Item
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter company name!',
                        },
                      ]}
                    >
                      <Input placeholder='Company Name' />
                    </Form.Item>

                    <Form.Item
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter tax ID!',
                        },
                      ]}
                    >
                      <Input placeholder='Tax ID' />
                    </Form.Item>
                  </>
                )} */}

                {isNew && (
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas unesite šifru!',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Šifra' />
                  </Form.Item>
                )}

                <Form.Item
                  name='role'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas izaberite rolu!',
                    },
                  ]}
                >
                  <Select mode='multiple' placeholder='Rola'>
                    <Select.Option value='admin'>Admin</Select.Option>
                    <Select.Option value='user'>User</Select.Option>
                  </Select>
                </Form.Item>

                {/* {!isNew && (
                  <Form.Item
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas izaberite status!',
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                      <Select.Option value='DELETED'>DELETED</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
                    </Select>
                  </Form.Item>
                )} */}

                <Form.Item {...tailLayout}>
                  <div>
                    <Button type='primary' htmlType='submit' style={{ float: 'right' }} onClick={onClickHandler}>
                      {data ? 'Snimi izmene' : 'Dodaj komitenta'}
                    </Button>
                    <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                      Otkaži
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='Komitent je kreiran i verifikacioni mail je poslat'
                extra={
                  <Button
                    type='primary'
                    key='console'
                    onClick={() => {
                      setResult(false);
                      window.location.reload();
                    }}
                  >
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteClientHandler(client._id)}
            okText='Da'
            cancelText='Ne'
          >
            <p>Da li ste sigurni da želite da obrišete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ClientForm;
