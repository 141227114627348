import React from 'react';
import { Button, Form, Row, Col, Select, Typography, Card, Table } from 'antd';
const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const orderStatus = ['On hold', 'Delivered', 'Not delivered'];

  const onFinish = async (values) => {
    onSubmit(values);
  };
  const columns = [
    {
      key: '1',
      title: 'product',
      dataIndex: 'name',
    },
    {
      key: '2',
      title: 'category',
      dataIndex: 'category',
    },
    {
      key: '3',
      title: 'price',
      dataIndex: 'price',
    },
    {
      key: '4',
      title: 'quantity',
      dataIndex: 'quantity',
    },
  ];
  let fullName = '';
  if (data.user) {
    fullName += data.user.firstName ? data.user.firstName + ' ' : '';
    fullName += data.user.lastName ? data.user.lastName : '';
  }
  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Order information' bordered={false}>
                  <p>
                    <Text strong>Order Number: </Text>
                    {data.orderNumber ? data.orderNumber : 'No data'}
                  </p>
                  <p>
                    <Text strong>Buyer: </Text>
                    {fullName !== '' ? fullName : `${data.guestUserData.firstName} ${data.guestUserData.lastName}`}
                  </p>
                  <p>
                    <Text strong>City: </Text>
                    {data.user && data.user.city ? data.user.city : data.guestUserData.city}
                  </p>
                  <p>
                    <Text strong>Total Price: </Text>
                    {data.totalPrice ? data.totalPrice : 'No data'}
                  </p>
                </Card>
                <Form.Item label='Order Status' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className='text-right'>
                  <Button type='primary' htmlType='submit'>
                    Update
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table
                  pagination={false}
                  rowKey='_id'
                  style={{ margin: '0px 0px 40px 0px' }}
                  columns={columns}
                  dataSource={data.order}
                  bordered
                />
                <div className='text-right'>
                  <Button type='primary' onClick={createInvoice}>
                    Create Invoice
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
