import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
} from '@ant-design/icons';
// import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          <img src='/galena-lab-logo.png' style={{ maxHeight: '49px' }} alt='Universal api' />
        </div>
      </Link>
      <div className='sidebar-wrapper'>
        <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
          {ROUTES.map((route) => (
            <SubMenu
              className='submenu'
              key={route.label.toLowerCase()}
              title={
                isCollapsed ? (
                  <route.icon />
                ) : (
                  <span>
                    {/*<route.icon />*/}
                    {route.label}
                  </span>
                )
              }
            >
              {route.children
                .filter((item) => item.showInMenu)
                .map((item) => (
                  <Menu.Item key={item.path}>
                    {item.label === 'View' ? (
                      <Link to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}>{item.label}</Link>
                    ) : (
                      <Link to={item.path}>{item.label}</Link>
                    )}
                  </Menu.Item>
                ))}
            </SubMenu>
          ))}
        </Menu>
        <div className='log-out-button-wrapper'>
          <Button className='brown-border-button' type='secondary' onClick={handleSignOutSubmit}>
            Odjavi se
          </Button>
        </div>
      </div>
    </Sider>
  );
};
export default NavMenu;
