import React, { useEffect, useState } from 'react';
import { DatePicker, Table, Card } from 'antd';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ExportExcel from '../csv/ExportExcel';
import moment from 'moment';

const { RangePicker } = DatePicker;

const UserLog = ({ data, updateHandler, csv }) => {
  const dateFormat = 'DD.MM.YYYY';
  const user = data ? { ...data } : { email: '', password: '', role: [] };
  let dataTable = [];
  data.forEach((val, index) => {
    let dateId = moment(new Date(val._id)).format('DD.MM.YYYY');
    let logs = val.logs;
    let count = val.count;
    let obj = {
      _id: dateId,
      logs: logs,
      key: index,
      count: count,
    };
    dataTable.push(obj);
  });

  const onFinish = (value) => {
    setRange(value);
    let filter = { from: moment(value[0]).format('YYYY-MM-DD'), to: moment(value[1]).format('YYYY-MM-DD') };
    updateHandler(filter);
  };

  let sevenDayBefor = moment().subtract(7, 'day').format(dateFormat);
  let sevenDayBeforGet = moment(sevenDayBefor, dateFormat);

  const [arr, setArr] = useState([]);
  const [chartrange, setChartRange] = useState([sevenDayBeforGet, moment(new Date(), dateFormat)]);
  const [logcount, setLogCount] = useState([]);
  const [range, setRange] = useState([sevenDayBeforGet, moment(new Date(), dateFormat)]);

  useEffect(() => {
    if (data) {
      if (data.length > 0) {
        let sevenDayBefor = moment().subtract(7, 'day').format('YYYY-MM-DD');
        let sevenDayBeforGet = moment(sevenDayBefor).format('YYYY-MM-DD');
        for (let d = new Date(sevenDayBeforGet); d <= new Date(); d.setDate(d.getDate() + 1)) {
          let index = data.findIndex((item) => item._id === moment(d).format('YYYY-MM-DD'));
          if (index !== -1) {
            let idDate = moment(d).format('YYYY-MM-DD');
            idDate = String(idDate);
            if (index >= 0) {
              arr.push({
                _id: idDate,
                logs: [],
                count: data[index].count,
              });
            }
          }
        }
      } else {
        for (let d = new Date(user.createdAt); d <= new Date(); d.setDate(d.getDate() + 1)) {
          arr.push({ _id: moment(d).format('DD.MM.YYYY'), logs: [], count: 0 });
        }
      }

      let logCount = 0;
      for (let i = 0; i < arr.length; i++) {
        let numLog = arr[i].count;
        logCount = logCount + numLog;
      }
      setLogCount(logCount);
      setArr(arr);
    }
  }, [arr, data, user.createdAt]);

  let logCount = 0;
  const handleRange = (value) => {
    let arr = [];
    if (value.length > 0) {
      for (let d = new Date(value[0]); d <= new Date(value[1]); d.setDate(d.getDate() + 1)) {
        let index = data.findIndex((item) => item._id === moment(d).format('YYYY-MM-DD'));
        if (index >= 0) {
          arr.push(data[index]);
        }
      }
      setArr(arr);
    }

    logCount = 0;
    for (let i = 0; i < arr.length; i++) {
      let numLog = arr[i].count;
      logCount = logCount + numLog;
    }
    setChartRange(value.length > 0 ? value : []);

    setLogCount(logCount);
  };

  const expandedRowRender = (record) => {
    const columns = [
      { title: 'email', dataIndex: 'email', key: 'email' },
      { title: 'browser', dataIndex: 'browser', key: 'browser' },
      { title: 'version', dataIndex: 'version', key: 'version' },
      { title: 'ip', dataIndex: 'ip', key: 'ip' },
      { title: 'description', dataIndex: 'description', key: 'description' },
      {
        title: 'created',
        key: 'createdAt',
        render: (value) => <span>{moment(value.createdAt).format('DD.MM.YYYY HH:mm')}</span>,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.logs.map((log, index) => {
          log.key = index;
          return log;
        })}
        pagination={{ hideOnSinglePage: true }}
      />
    );
  };

  function formatXAxis(tickItem) {
    return moment(tickItem).format(dateFormat);
  }

  const columns = [{ title: 'Date', dataIndex: '_id', key: '_id' }];

  return (
    <div className='dashboard'>
      <Card title={'Broj prijava u izabranom intervalu: ' + logcount} bordered={false}>
        <div className='log-graph'>
          <RangePicker
            value={chartrange}
            allowClear
            size='large'
            format={dateFormat}
            onChange={(value) => handleRange(value)}
          />

          <ResponsiveContainer width='100%' aspect={16.0 / 5.0}>
            <LineChart
              data={arr}
              margin={{
                top: 24,
                left: -24,
                right: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='_id' tickFormatter={formatXAxis} axisLine={{ stroke: '#4f66a0', strokeWidth: 2 }} />
              <YAxis axisLine={{ stroke: '#4f66a0', strokeWidth: 2 }} />
              <Tooltip />
              <Line type='basis' dataKey='count' strokeWidth={1} stroke='#4f66a0' activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>

      {/* <Card title='Log range for export' bordered={false} style={{ marginTop: 16 }}> */}
      <Card title='Opseg prijava za izvoz' bordered={false} style={{ marginTop: 16 }}>
        <RangePicker value={range} size='large' format={dateFormat} onChange={(value) => onFinish(value)} />

        <ExportExcel
          csvData={csv != null && csv.length >= 1 ? csv : []}
          fileName={`logs ` + moment(new Date()).format('DD:MM:YYYY HH:MM')} // Ime xlsx fajla
          propsToRemove={['_id', 'objectId', 'userId', 'url', 'description', 'updatedAt', '__v']} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
          // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
        />
      </Card>

      <Card title='Informacije o prijavama' bordered={false} style={{ marginTop: 16 }}>
        <Table
          showHeader={false}
          columns={columns}
          expandedRowRender={expandedRowRender}
          dataSource={dataTable}
          className='components-table-demo-nested'
        />
      </Card>
    </div>
  );
};

export default UserLog;
