import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { notification, Button, Modal, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';

import { SERVER_URL } from '../../config';
import EditUserModal from '../../components/modals/EditModal';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'createdAt',
  'updatedAt',
  'avatar',
];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [showModal, setShowModal] = useState();
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();
  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
    setUseri(users);
  }, [fetchUsers]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/users/search/${search}`)
        .then((res) => {
          setUseri(res.data.users);
        })
        .catch((err) => console.log(err));
    } else {
      setUseri(users.data.items);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je izbrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  const columnKeysTranslated = ['Ime', 'Prezime', 'Grad', 'Rola', 'Status', 'Email'];

  let tableData = [];
  if (useri && useri.length > 0) {
    tableData = useri.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  } else if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className='table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Input
          bordered={false}
          placeholder='Pretraži'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/*<Link to='/admin/new-user'>*/}
        <Button onClick={() => setShowModal(!showModal)} type='primary'>
          Dodaj korisnika
        </Button>
        {/*</Link>*/}
      </div>

      <div style={{ textAlign: 'center' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            columnKeysTranslated={columnKeysTranslated}
            title='User'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
      <EditUserModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Users;
