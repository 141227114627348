import React from 'react';
import { Button, Card, Col, Form, Row, Select, Table, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select;

const formInit = {
  _id: null,
  status: '',
};

const RefundForm = ({ data, onSubmit }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const onFinish = async (values) => {
    onSubmit(values);
  };

  let fullName = '';
  if (data.userId) {
    fullName += data.userId.firstName ? data.userId.firstName + ' ' : '';
    fullName += data.userId.lastName ? data.userId.lastName : '';
  }
  const refundStatus = ['Podnet', 'Primljen', 'Uspesno vracen'];
  const columns = [
    {
      key: '1',
      title: 'Product name',
      dataIndex: 'productName',
    },
    {
      key: '2',
      title: 'Product code',
      dataIndex: 'code',
    },
    {
      key: '3',
      title: 'Required quantity',
      dataIndex: 'quantity',
    },
    {
      key: '4',
      title: 'Quantity',
      dataIndex: 'orderQuantity',
    },
    {
      key: '5',
      title: 'Refund reason',
      dataIndex: 'refundReason',
    },
  ];

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form className='form-horizontal' initialValues={initialValues} onFinish={(values) => onFinish(values)} layout='vertical' form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Refund information' bordered={false}>
                  <p>
                    <Text strong>Refund Number: </Text>
                    {data.refundNumber ? data.refundNumber : 'No data'}
                  </p>

                  <p>
                    <Text strong>User: </Text>
                    {fullName !== '' ? fullName : 'No data'}
                  </p>

                  <p>
                    <Text strong>Order Number: </Text>
                    {data.orderId && data.orderId.orderNumber ? data.orderId.orderNumber : 'No data'}
                  </p>

                  <p>
                    <Text strong>Comment: </Text>
                    {data.comment ? data.comment : 'No data'}
                  </p>

                  <p>
                    <Text strong>Created At: </Text>
                    {data.createdAt ? new Date(data.createdAt).toLocaleString() : 'No data'}
                  </p>
                </Card>

                <Form.Item label='Order Status' name='status'>
                  <Select>
                    {refundStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className='text-right'>
                  <Button type='primary' htmlType='submit'>
                    Update
                  </Button>
                </div>
              </div>
            </Col>

            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table pagination={false} rowKey='_id' style={{ margin: '0px 0px 40px 0px' }} columns={columns} dataSource={data.refund} bordered />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default RefundForm;
