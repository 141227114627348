import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button, Modal, Avatar } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import EditUserModal from '../modals/EditModal';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';

const UsersTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, columnKeysTranslated }) => {
  const user = useContext(UserContext);
  const [userID, setUserID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deletePop, setDeletePop] = useState();
  const [users, fetchUsers] = useAxios('', null, user.data.token, 'get');
  //moram koristiti state za izlistavanje imena jer iz nekog razloga vuce samo ime Test Concorda
  const [promptName, setPromptName] = useState();
  const [deleteUser, setDeleteUser] = useState();

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, []);

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraži ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Pretraži
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    setUserID(ID);
    setShowModal(!showModal);
  };

  const getUrl = (itemUrl) => {
    let url = itemUrl?.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  const columns = columnKeys.map((item, index) => ({
    key: item,
    // title:
    //   item === 'firstName'
    //     ? 'First name'
    //     : item === 'lastName'
    //     ? 'Last name'
    //     : `${item.charAt(0).toUpperCase()}${item.slice(1)}`,
    title: columnKeysTranslated.map((x) => x)[index],
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  // columns.unshift({
  //   title: 'Avatar',
  //   render: (text, record) => (
  //     <div>
  //       <Avatar size={60} src={record && record.avatar ? getUrl(record.avatar.url) : 'Logo'} />
  //     </div>
  //   ),
  // });
  columns.push({
    title: 'Akcija',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <EditOutlined
            title='Izmeni korisnika'
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title='Pregled korisnika' style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px' }}>
          <DeleteOutlined
            title='Obriši korisnika'
            onClick={() => {
              setDeletePop(!deletePop);
              setPromptName(record.firstName);
              setDeleteUser(record._id);
            }}
          />
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteHandler(deleteUser)}
            okText='Da'
            cancelText='Ne'
          >
            <p>Da li ste sigurni da želite da obrišete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
      <EditUserModal userId={userID} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default UsersTable;
