import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { notification, Button, Modal, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
// import { Table } from '../../components/tables';
import Table from '../../components/tables/ClientsTable';

import { SERVER_URL } from '../../config';
import EditClientModal from '../../components/modals/EditClientModal';
// import { get } from 'mongoose';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  // 'address',
  'zip',
  // 'taxId',
  // 'phone',
  'legalType',
  'wishList',
  'country',
  'companyName',
  'createdAt',
  'updatedAt',
  'avatar',
];

const Clients = () => {
  const currentuser = useContext(UserContext);
  // const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');
  const [showModal, setShowModal] = useState();
  const [search, setSearch] = useState();
  const [klienti, setKlienti] = useState();
  const [clients, setClients] = useState();
  // useEffect(() => {
  //   fetchClients(`${SERVER_URL}/clients`, []);
  //   setKlienti(clients);
  // }, [fetchClients]);

  useEffect(() => {
    getClientsData();
  }, []);

  const getClientsData = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/clients`);
      setKlienti(response);
      setClients(response);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers

  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/clients/search/${search}`)
        .then((res) => {
          setKlienti(res.data.clients);
        })
        .catch((err) => console.log(err));
    } else {
      // setKlienti(clients.data.items);
      setKlienti(clients);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je izbrisana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  if (clients?.data?.items && clients?.data?.items.length > 0) {
    const keys = Object.keys(clients?.data?.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  const columnKeysTranslated = ['Adresa', 'Grad', 'PIB', 'Telefon', 'Naziv', 'Email'];

  let tableData = [];
  if (klienti && klienti.length > 0) {
    tableData = klienti.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  } else if (clients?.data?.items && clients?.data?.items?.length > 0) {
    tableData = clients?.data?.items?.map((item) => {
      if (Array.isArray(item?.role)) item.role = item?.role.join(', ');
      item.createdAt = new Date(item?.createdAt).toLocaleString();
      item.updatedAt = new Date(item?.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className='table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Input
          bordered={false}
          placeholder='Pretraži'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/*<Link to='/admin/new-user'>*/}
        <Button onClick={() => setShowModal(!showModal)} type='primary'>
          Dodaj komitenta
        </Button>
        {/*</Link>*/}
      </div>

      <div style={{ textAlign: 'center' }}>
        {/* {clients.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!clients.isLoading && clients?.data?.items && clients?.data?.items?.length > 0 && ( */}
        {clients?.data?.items && clients?.data?.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            columnKeysTranslated={columnKeysTranslated}
            title='Komitent'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-client/'
          />
        )}
        {/* {!clients.isLoading && clients.data.items && clients.data.items.length === 0 && <h2>NO DATA</h2>} */}
        {clients?.data?.items && clients?.data?.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
      <EditClientModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Clients;
