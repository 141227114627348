import React from 'react';

const Home = () => {
  return (
    <div style={{ textAlign: 'center', overflowX: 'auto'}}>
      <h1>HOME</h1>
    </div>
  );
};

export default Home;
